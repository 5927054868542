import { AppState, Auth0Provider, Auth0ProviderOptions, User } from '@auth0/auth0-react';
import { useAuthentication } from 'hooks/useAuthentication';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/* eslint-disable react-hooks/exhaustive-deps */
export const Auth0ProviderWithHistory = (props: Omit<Auth0ProviderOptions, 'domain' | 'clientId' | 'redirectUri' | 'audience' | 'onRedirectCallback'>): JSX.Element => {
  const domain = process.env.REACT_APP_AUTH_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const { permissions } = useAuthentication();

  const navigate = useNavigate();

  useEffect(() => {
    if (permissions && permissions.length > 0) {
      if (!permissions.includes(process.env.REACT_APP_APPLICATION_ACCESS_PERMISSION_NAME)) navigate('/unauthorized');
    }
  }, [permissions]);

  const onRedirectCallback = (appState: AppState | undefined, user?: User | undefined): void => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return <Auth0Provider {...props} domain={domain} clientId={clientId} redirectUri={window.location.origin} audience={process.env.REACT_APP_AUDIENCE} onRedirectCallback={onRedirectCallback} />;
};
