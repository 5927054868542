import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  selectedDivisionApplicationsToCreate: string[];
  selectedDivisionApplicationsToDelete: string[];
}

const initialState: InitialState = {
  selectedDivisionApplicationsToCreate: [],
  selectedDivisionApplicationsToDelete: []
};

export const selectedDivisionApplicationsSlice = createSlice({
  name: 'selectedDivisionApplications',
  initialState,
  reducers: {
    addDivisionApplicationToCreate: (state, { payload }: PayloadAction<string>) => {
      state.selectedDivisionApplicationsToCreate = [...state.selectedDivisionApplicationsToCreate, payload];
    },
    removeDivisionApplicationToCreate: (state, { payload }: PayloadAction<string>) => {
      state.selectedDivisionApplicationsToCreate = state.selectedDivisionApplicationsToCreate.filter((item) => item !== payload);
    },
    setSelectedDivisionApplicationsToCreate: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedDivisionApplicationsToCreate = payload;
    },
    addDivisionApplicationToDelete: (state, { payload }: PayloadAction<string>) => {
      state.selectedDivisionApplicationsToDelete = [...state.selectedDivisionApplicationsToDelete, payload];
    },
    removeDivisionApplicationToDelete: (state, { payload }: PayloadAction<string>) => {
      state.selectedDivisionApplicationsToDelete = state.selectedDivisionApplicationsToDelete.filter((item) => item !== payload);
    },
    setSelectedDivisionApplicationsToDelete: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedDivisionApplicationsToDelete = payload;
    }
  }
});

export const {
  addDivisionApplicationToCreate,
  removeDivisionApplicationToCreate,
  setSelectedDivisionApplicationsToCreate,
  addDivisionApplicationToDelete,
  removeDivisionApplicationToDelete,
  setSelectedDivisionApplicationsToDelete
} = selectedDivisionApplicationsSlice.actions;
