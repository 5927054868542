import { Card, List, message, Modal, Typography } from 'antd';
import { Business } from 'models/Business';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoverDivisionMutation } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { setCustomerSearch } from 'redux/slices/businessListSlice';

const { Paragraph } = Typography;

interface Props {
  division: Business['divisions'][number];
}
const styles: InlineStylesModel = {
  cardBody: {
    borderRadius: 10,
    padding: 0,
    height: '100%'
  },
  customerInfo: {
    padding: '10px 60px'
  },
  title: {
    fontSize: '0.75 em',
    margin: 0,
    color: '#2786fa'
  },
  values: {
    fontSize: '1 em',
    fontWeight: 600
  }
};

export const DivisionListItem = ({ division }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isRecycleBinPage = pathname.includes('recycling-bin');
  const [recoverDivision] = useRecoverDivisionMutation();

  /* ******************** Variables / Functions ******************** */
  const handleOk = async (): Promise<void> => {
    try {
      await recoverDivision({ divisionId: division.id as string }).unwrap();
      message.success('Division successfuly recovered');
    } catch (error) {
      console.log(error);
      message.error((error as { data: string }).data ?? 'Division failed to be recovered!');
    }
  };
  const modal = () =>
    Modal.confirm({
      title: `Recover Division ${division.name}`,
      onOk: handleOk,
      content: 'Do you wish to recover this division?'
    });
  const handleEditDivision = (): void => {
    if (isRecycleBinPage) {
      modal();
    } else {
      navigate(`/division/${division.id}`);
      dispatch(setCustomerSearch(''));
    }
  };

  /* ******************** Renderer ******************** */
  return (
    <List.Item colStyle={{ height: '100%' }} style={{ height: '100%', marginBottom: 0 }}>
      <Card bodyStyle={{ padding: 10 }} hoverable style={{ height: '100%' }} onClick={handleEditDivision}>
        <Paragraph style={styles.title} ellipsis={{ tooltip: `${division.name}` }}>
          {division.name}
        </Paragraph>
        <Paragraph style={{ marginBottom: 0 }}>{division.erpId}</Paragraph>
      </Card>
    </List.Item>
  );
};
