import { About } from 'pages/About';
import { CreateBusinessPage } from 'pages/CreateBusiness';
import { CreateDivisionPage } from 'pages/CreateDivision';
import { EditBusinessPage } from 'pages/EditBusiness';
import { EditDivisionPage } from 'pages/EditDivision';
import { EditLayout } from 'pages/EditLayout';
import { EditProfilePage } from 'pages/EditProfile';
import { Forbidden } from 'pages/Forbidden';
import { HomePage } from 'pages/Home';
import { RecyclingBinPage } from 'pages/RecyclingBin';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<About />} />
      <Route path="/unauthorized" element={<Forbidden />} />
      <Route path="/profile" element={<EditProfilePage />} />
      <Route path="/businesses/create-business" element={<CreateBusinessPage />} />
      <Route path="/divisions/create-division" element={<CreateDivisionPage />} />
      <Route path="/customers/recycling-bin" element={<RecyclingBinPage />} />
      <Route path="/business/:id" element={<EditLayout />} />
      <Route path="/business/:id" element={<EditLayout />}>
        <Route index element={<EditBusinessPage />} />
      </Route>
      <Route path="/division/:id" element={<EditLayout />}>
        <Route index element={<EditDivisionPage />} />
      </Route>
    </Routes>
  );
};
