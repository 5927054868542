import { Space } from 'antd';
import { Outlet } from 'react-router-dom';

export const EditLayout = (): JSX.Element => {
  /* ******************** Renderer ******************** */
  return (
    <Space direction="vertical">
      <Outlet />
    </Space>
  );
};
