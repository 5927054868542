import { DeleteOutlined, EditOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Space, Tooltip, message } from 'antd';
import { SwitchInput, TextInput } from 'components/UI/FormItems';
import { TextAreaInput } from 'components/UI/FormItems/TextAreaInput';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { CourrierAccountModel, CreateDivisionPayload, courrierAccountPayload } from 'models/Division';
import { FC, useState } from 'react';

type Props = {
  record: CourrierAccountModel;
  idx: number;
};

export const EditCourierAcount: FC<Props> = ({ record, idx }) => {
  const { values, setFieldValue } = useFormikContext<CreateDivisionPayload>();
  const [isOpen, setIsOpen] = useState(false);

  const [{ value }, _, { setValue }] = useField<CourrierAccountModel>(`courierAccounts[${idx}]`);
  const formik = useFormik<CourrierAccountModel>({
    enableReinitialize: true,
    validationSchema: courrierAccountPayload,
    initialValues: {
      courierErpCode: value.courierErpCode ?? '',
      accountNumber: value.accountNumber ?? '',
      description: value.description ?? '',
      priority: value.priority ?? 1,
      isDefault: value.isDefault ?? false
    },
    onSubmit: (vals) => {
      const currentValues = values.courierAccounts.filter((acc) => acc !== record);
      if (currentValues.some((acc) => acc.accountNumber.toLowerCase() === vals.accountNumber.toLowerCase())) {
        message.error('Account number already exists');
        return;
      }
      if (currentValues.some((acc) => acc.courierErpCode.toLowerCase() === vals.courierErpCode.toLowerCase())) {
        message.error('Courier Erp Code already exists');
        return;
      }
      if (currentValues.some((acc) => acc.isDefault === true) && vals.isDefault) {
        message.error('There can only be one default account');
        return;
      }
      setValue(vals);
      setIsOpen(false);
      formik.resetForm();
    }
  });

  const handleDelete = () => {
    setFieldValue(
      'courierAccounts',
      values.courierAccounts.filter((account) => account !== record).map((arr, idx) => ({ ...arr, priority: idx + 1 }))
    );
  };
  return (
    <FormikProvider value={formik}>
      <Space>
        <Tooltip title="Edit">
          <Button onClick={() => setIsOpen(true)} size="small" icon={<EditOutlined />} />
        </Tooltip>
        <Tooltip title="Delete">
          <Button
            onClick={() =>
              Modal.confirm({
                title: 'Are you sure you want to delete this account? The changes will not go into effect until you hit save.',
                onOk: handleDelete,
                icon: <WarningOutlined style={{ color: 'red' }} />
              })
            }
            size="small"
            danger
            icon={<DeleteOutlined />}
          />
        </Tooltip>
      </Space>
      <Modal onOk={() => formik.submitForm()} width={350} onCancel={() => setIsOpen(false)} open={isOpen}>
        <Form layout="vertical" style={{ width: '100%' }}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <TextInput fieldName="courierErpCode" label="Courier Erp Code" />
            <TextInput fieldName="accountNumber" label="Courrier Account Number" />
            <TextAreaInput fieldName="description" label="Description" />
            <SwitchInput fieldName="isDefault" label="Is Default" labelPosition="top" isInheritanceBusiness={false} />
          </Space>
        </Form>
      </Modal>
    </FormikProvider>
  );
};
