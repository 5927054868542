import { AppstoreOutlined, EditFilled, SaveOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Col, message, Modal, Row, Spin, Upload, UploadProps } from 'antd';
import { AntButton } from 'components/UI/AntButton';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React, { useCallback, useState } from 'react';
import Cropper, { Area, Point } from 'react-easy-crop';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-use';
import { useGetBusinessQuery, useUpdateBusinessLogoMutation } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { cookieMonsterApi, useGetUserQuery, useUpdateUserProfileImageMutation } from 'redux/services/cookieMonster/cookieMonsterApi';
import { getCroppedImg } from 'utils/canvasUtils';

const styles: InlineStylesModel = {
  cropContainer: {
    height: '60vh'
  },
  controls: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    width: '100%',
    transform: 'translateX(-50%)',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingLeft: 10
  },
  title: {
    position: 'absolute',
    top: 0,
    left: '50%',
    width: '100%',
    transform: 'translateX(-50%)',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    zIndex: 1,
    justifyContent: 'center'
  }
};

export const LogoUpload = (profileData: any) => {
  const { id: customerId } = useParams();
  const { data: customerData } = useGetBusinessQuery(customerId as string, { skip: !customerId });
  const [updateProfileImage, { isLoading: isProfileUpdating }] = useUpdateUserProfileImageMutation();
  const [updateBusinessLogo, { isLoading: isBusinessUpdating }] = useUpdateBusinessLogoMutation();

  const { pathname } = useLocation();

  const { user } = useAuth0();
  const userId = user?.sub;
  const { data: userData, isLoading, isFetching } = useGetUserQuery(userId as string, { skip: !userId });

  const isEditBusinessPage = pathname?.includes('/business/');

  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = React.useState('');
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(2);
  const [hover, setHover] = useState(false);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [croppedImage, setCroppedImage] = useState<string>('');

  function readFile(file: any) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  // const handlePreviewCroppedImage = useCallback(async () => {
  //   try {
  //     const croppedImageSrc = await getCroppedImg(imageSrc, croppedAreaPixels);
  //     setCroppedImage(croppedImageSrc?.toDataURL('image/png') as any);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, [croppedAreaPixels]);

  const handleCroppedImageUpload = useCallback(async () => {
    if (!userId || !customerId) return message.error('No entity found, please refresh the page');
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      croppedImage?.toBlob((blob: BlobPart | any) => {
        const fileCropped = new File([blob], `logo${new Date().toISOString()}.jpeg`, { type: 'image/jpeg' });
        const formData = new FormData();
        formData.append('logo.jpeg', fileCropped);
        if (isEditBusinessPage) updateBusinessLogo({ businessId: customerId, payload: formData });
        else updateProfileImage({ userId, payload: formData });
      }, 'image/jpeg');
      message.success('Logo successfully changed');
    } catch (error) {
      message.error('Logo could not be changed at this time');
      console.error(error);
    }
  }, [userId, imageSrc, croppedAreaPixels, updateProfileImage]);

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onOk = () => {
    try {
      setCroppedImage('');
      setImageSrc('');
      setShowModal(false);
      handleCroppedImageUpload();
      cookieMonsterApi.util.invalidateTags(['User']);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = () => {
    if (!customerId || !userId) return message.error('No Id found, please refresh the page');
    try {
      const emptyLogo = new FormData();
      emptyLogo.append('logo.jpeg', '');
      isEditBusinessPage ? updateBusinessLogo({ businessId: customerId, payload: emptyLogo }) : updateProfileImage({ userId, payload: emptyLogo });
    } catch (error) {
      console.log(error);
    }
  };

  // const onClose = useCallback(() => {
  //   setCroppedImage('null');
  // }, []);

  const handleCloseModal = () => {
    setCroppedImage('');
    setImageSrc('');
    setShowModal(false);
  };

  const props: UploadProps = {
    name: 'file',
    async onChange(info: any) {
      setShowModal(true);
      if (info.file) {
        const file = info.file.originFileObj;
        let imageDataUrl = await readFile(file);

        setImageSrc(imageDataUrl as any);
      }
    }
  };

  const validateSrc = () => {
    if (customerData) return customerData.logoUrl ? customerData.logoUrl : undefined;
    if (userData && userData?.user_metadata.profile_image_url) return userData?.user_metadata.profile_image_url;
    else if (!isLoading || !isFetching) return user?.picture;
  };

  return (
    <div style={{ paddingTop: 32 }}>
      {imageSrc ? (
        <React.Fragment>
          <Modal width={800} visible={showModal} onCancel={handleCloseModal} onOk={onOk}>
            <div style={styles.cropContainer}>
              <Cropper cropShape="round" showGrid={false} image={imageSrc} crop={crop} zoom={zoom} aspect={1} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} />
            </div>
            <div style={styles.controls}>
              <input
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  setZoom(e.target.value as any);
                }}
                className="zoom-range"
              />
            </div>
          </Modal>
        </React.Fragment>
      ) : (
        <>
          <Row align="middle">
            <Upload {...props}>
              <Col style={{ borderRadius: 50 }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <Avatar
                  icon={isLoading || isFetching ? <Spin spinning></Spin> : <AppstoreOutlined />}
                  style={{ cursor: 'pointer', filter: hover ? 'brightness(50%)' : '', transition: '0.25s ease-in-out', position: 'relative' }}
                  size={100}
                  alt=""
                  src={validateSrc()}
                />
                {hover && (
                  <Avatar
                    icon={<EditFilled />}
                    style={{ cursor: 'pointer', filter: hover ? 'brightness(60%)' : '', transition: '0.25s ease-in-out', position: 'absolute', left: 0, opacity: 0.65 }}
                    size={100}
                    alt=""
                  />
                )}
              </Col>
            </Upload>
            <Col>
              <AntButton
                style={{ marginLeft: 16 }}
                disabled={isEditBusinessPage ? !customerData?.logoUrl : !userData?.user_metadata.profile_image_url}
                onClick={handleDelete}
                loading={isProfileUpdating || isBusinessUpdating}
                danger
                type="ghost"
                icon={<SaveOutlined />}>
                Delete Current Picture
              </AntButton>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
