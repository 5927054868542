import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { ReduxState } from 'redux/store';

/* ******************** Base Query ******************** */
const baseUrl = process.env.REACT_APP_MRS_GROUCH_BASE_URL || '';
const functionsKey = process.env.REACT_APP_API_HOST_KEY_MRS_GROUCH || '';

const mrsGrouchBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const divisionId = (api.getState() as ReduxState).app.acuityContext?.selectedCustomer.id;
  const isApiVersion = typeof args !== 'string' && args.url === '/diagnostics/version';

  if (!divisionId && !isApiVersion) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No division Id received'
      }
    };
  }

  const urlEnd = typeof args === 'string' ? args : args.url;
  const adjustedUrl = isApiVersion ? args.url : `divisions/${divisionId}/${urlEnd}`;
  const adjustedArgs = typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };

  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as ReduxState).app.accessToken;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('x-functions-key', functionsKey);
        headers.set('Content-Type', 'application/json');
      }

      return headers;
    }
  })(adjustedArgs, api, extraOptions);
};

export const mrsGrouchApi = createApi({
  reducerPath: 'mrsGrouchApi',
  baseQuery: mrsGrouchBaseQuery,
  endpoints: (builder) => ({
    getProjects: builder.query<any, void>({
      query: () => ({
        url: 'projects'
      })
    }),
    getVersion: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/version',
        responseHandler: (response): Promise<string> => response.text()
      })
    })
  })
});

export const { useGetProjectsQuery, useGetVersionQuery } = mrsGrouchApi;
