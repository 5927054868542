import { Col, Form, Row } from 'antd';
import { TextInput } from 'components/UI/FormItems';
import { TextInputPassword } from 'components/UI/FormItems/TextInputPassword';

export const BICustomerForm = (): JSX.Element => {
  return (
    <Form layout="vertical">
      <Row gutter={[32, 16]}>
        <Col span={12}>
          <TextInput fieldName="biLogin.userId" label="User Id" />
        </Col>
        <Col span={12}>
          <TextInput fieldName="biLogin.spaceId" label="Space ID" />
        </Col>
        <Col span={12}>
          <TextInputPassword fieldName="biLogin.ssoPassword" label="SSO Password" />
        </Col>
      </Row>
    </Form>
  );
};
