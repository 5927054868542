import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Form, message, Select, SelectProps } from 'antd';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetOwnersQuery } from 'redux/services/animals/animalsApi';
import { useGetDeploymentRequestTypesQuery } from 'redux/services/drNobelPrice/drNobelPriceApi';
import { ReduxState } from 'redux/store';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps {
  fieldName: string;
  label?: string;
  businesses?: any;
}

export const AssignOwnersSelectInput = ({ fieldName, businesses, label, ...rest }: Props): JSX.Element => {
  const [{ value }, { touched, error }, { setTouched, setValue }] = useField<string[]>(fieldName);

  const { animalsOwnersParams } = useSelector((state: ReduxState) => state);
  const { id: divisionId } = useParams();

  const { data, isLoading } = useGetOwnersQuery(animalsOwnersParams);
  const { data: deploymentRequestTypeData } = useGetDeploymentRequestTypesQuery(divisionId ?? skipToken);

  // Get all owners from each exisiting config type and flatten it into one array. Converting to set then back to array filters out duplicates
  const uniqueFlatOwners = [...new Set(deploymentRequestTypeData?.data.map((type) => type.dimensionFulfillmentRules.map((rule) => rule.ownerId)).flat())];

  const options = data?.data.map((owner) => ({ label: owner.name, value: owner.name }));

  const handleChange = (val: string[]) => {
    if (!uniqueFlatOwners.every((owner) => val.includes(owner))) {
      return message.error('This owner is currently being used in this customers deployment request configuration, it cannot be reomved.');
    }
    setValue(val);
  };

  return (
    <Form.Item
      labelAlign="left"
      validateStatus={touched && error ? 'error' : 'success'}
      label={label ? <FormLabel label={label} /> : undefined}
      help={touched && error ? error : undefined}
      style={{ marginBottom: 0 }}
      labelCol={{ style: { padding: 0 } }}>
      <Select
        {...rest}
        mode="multiple"
        loading={isLoading}
        options={options}
        value={value || undefined}
        onChange={(val) => handleChange(val)}
        onFocus={() => setTouched(false)}
        onBlur={() => setTouched(true)}
        style={{ borderRadius: 5 }}
      />
    </Form.Item>
  );
};
