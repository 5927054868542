import * as yup from 'yup';
import { ApplicationsModel } from './ApplicationsModel';
import { DivisionsModel } from './DivisionsModel';

export interface Business {
  acuityV3ErpId: string;
  applications: ApplicationsModel[];
  createdByFullName: string;
  createdDateTime: string;
  dataAreaId: string;
  description: string;
  divisions: DivisionsModel[];
  documentType: string;
  erpId: string;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  logoUrl: string;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  name: string;
  partitionKey: string;
  partitionKeyDescription: string;
  createdByUserId: string;
}
export interface GetBusinessesParams {
  includeDeletedData?: boolean;
  includeInactiveData?: boolean;
  returnDeletedBusinessesOnly?: boolean;
  orderByDirection?: string;
  orderByField?: string;
  businessNameContains?: string;
  businessDataAreaId?: string;
  includeDivisions?: boolean;
  includeInactiveDivisionData?: boolean;
  includeDeletedDivisionData?: boolean;
  returnDeletedDivisionsOnly?: boolean;
  orderDivisionByDirection?: string;
  orderDivisionByField?: string;
  divisionNameContains?: string;
}

export interface EditBusinessPayload {
  name: string;
  description: string;
  erpId: string;
  dataAreaId?: string;
}

export interface CreateBusinessPayload {
  name: string;
  description: string;
  erpId: string;
  dataAreaId: string;
  isActive: boolean;
}

export const businessPayload: yup.SchemaOf<EditBusinessPayload> = yup.object({
  name: yup.string().label('Name').required(),
  description: yup.string().label('Description').required(),
  erpId: yup.string().label('Erp Id').required(),
  dataAreaId: yup.string().label('Data Area Id').required()
});
