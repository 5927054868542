import { Col, Row } from 'antd';
import { FancyInput } from 'components/atoms/Inputs';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import React, { ChangeEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setCustomerSearch } from 'redux/slices/businessListSlice';
import { useDebouncedCallback } from 'use-debounce';

export const HomeControls: React.FC = () => {
  const { pathname } = useLocation();
  const isRecycleBinPage = pathname.includes('/recycling-bin');
  const dispatch = useDispatch();

  const handleCustomerSearchChange = useDebouncedCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setCustomerSearch(event.target.value));
  }, 500);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setCustomerSearch(undefined));
    };
  }, [dispatch]);

  return (
    <Row justify="space-between">
      <Row>
        <Col style={{ paddingRight: 16 }}>
          <Row justify="start">
            <AntPageTitle text={isRecycleBinPage ? 'Customer Management Recycle Bin' : 'Customer Management'} />
          </Row>
        </Col>
        {!isRecycleBinPage && (
          <Col style={{ paddingRight: 16 }}>
            <Row justify="start">
              <FancyInput placeholder="Search customer" onChange={handleCustomerSearchChange} />
            </Row>
          </Col>
        )}
      </Row>
    </Row>
  );
};
