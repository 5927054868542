import { List, Spin } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { DivisionApplicationListItem } from 'components/atoms/DivisionApplicationListItem';
import { useParams } from 'react-router-dom';
import { useGetApplicationsQuery } from 'redux/services/freddyCadabby/freddyCadabbyApi';
import { useGetDivisionApplicationsQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';

export const DivisionApplicationList = (): JSX.Element => {
  const { id: divisionId } = useParams();
  const divisionIdString = divisionId as string;

  const { data: assignedApplicationsData, isLoading: isAssignedLoading, isFetching: isAssignedFetching } = useGetDivisionApplicationsQuery(divisionIdString);
  const { data: applicationsData, isLoading, isFetching } = useGetApplicationsQuery({ isDivisionContextRequired: true });

  return (
    <Spin
      style={{ width: '100%' }}
      spinning={isLoading || isFetching || isAssignedFetching || isAssignedLoading}
      indicator={<BlockLoader style={{ marginTop: 25 }} direction="loader loader--slideUp" />}>
      <List
        grid={{ column: 3, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
        dataSource={applicationsData?.data}
        renderItem={(item): JSX.Element => (
          <DivisionApplicationListItem isAssignedLoading={isLoading} isAssignedFetching={isFetching} application={item} assignedApplications={assignedApplicationsData?.data} />
        )}
      />
    </Spin>
  );
};
