import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Card, Col, message, Row, Switch, Typography } from 'antd';
import { RudyApplicationModel } from 'models/Application';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useLocation } from 'react-router-dom';
import { useCreateDivisionApplicationMutation, useDeleteDivisionApplicationMutation } from 'redux/services/rudyCadabby/rudyCadabbyApi';

const { Paragraph } = Typography;

const styles: InlineStylesModel = {
  wrapper: {
    height: '100%'
  },
  container: {
    padding: 2
  },
  title: {
    fontSize: '0.85em',
    color: 'green',
    marginBottom: 0
  },
  titleContainer: {
    paddingLeft: 10
  },
  iconContainer: {
    paddingLeft: 10
  },
  editButton: {
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 7
  },
  subTitle: {
    fontSize: '0.75em',
    marginBottom: 0
  }
};

export const DivisionApplicationListItem = ({ application, assignedApplications, isAssignedLoading, isAssignedFetching }: any): JSX.Element => {
  /* ******************** Hooks ******************** */
  const [createDivisionApplication, { isLoading: isCreateLoading, isError: isCreateError }] = useCreateDivisionApplicationMutation();
  const [deleteDivisionApplication, { isLoading: isDeleteLoading, isError: isDeleteError }] = useDeleteDivisionApplicationMutation();
  const { pathname } = useLocation();

  const divisionId = pathname.includes('/division/') && pathname.split('/')[2];
  const stringDivisionId = divisionId as string;

  /* ******************** Variables / Functions ******************** */
  const handleChecked = async () => {
    const isAssigned = assignedApplications?.find((assignedApplicationsData: RudyApplicationModel) => assignedApplicationsData.application.id === application.id);
    console.log(isAssigned);
    try {
      if (isAssigned) {
        const response = await deleteDivisionApplication(isAssigned.id);
        if ((response as { error: { data: string } }).error) throw Error((response as { error: { data: string } }).error.data);
        message.success(`Removed ${application.name} from division`);
      } else {
        const response = await createDivisionApplication({ divisionId: stringDivisionId, payload: { applicationId: application.id } });
        if ((response as { error: { data: string } }).error) throw Error((response as { error: { data: string } }).error.data);
        message.success(`Added ${application.name} from Division Information`);
      }
    } catch (error: any) {
      console.log(error);
      message.error(error.message ?? 'Unable to assign application to division, please try again.');
    }
  };

  const checkingApps = () => {
    if (!isAssignedLoading && !isAssignedFetching) {
      return assignedApplications?.find((assignedApplicationsData: RudyApplicationModel) => assignedApplicationsData.application.id === application.id);
    }
  };

  /* ******************** Renderer ******************** */
  return (
    <Card bodyStyle={{ padding: 15 }} style={{ margin: '0 1px', marginBottom: 3 }}>
      <Row justify="space-between" align="middle">
        <Col span={18}>
          <Paragraph style={styles.title} ellipsis>
            {application.name}
          </Paragraph>
          <Paragraph style={styles.subTitle} ellipsis={{ rows: 1, tooltip: application.description }}>
            {application.description}
          </Paragraph>
        </Col>
        <Col span={6}>
          <Row justify="end">
            <Switch loading={isCreateLoading || isDeleteLoading} checked={checkingApps()} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} onClick={handleChecked} />
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
