import { Col, Form, Row } from 'antd';
import { TextInput } from 'components/UI/FormItems';
import { AssignOwnersSelectInput } from 'components/UI/FormItems/AssignOwnersSelectInput';
import { AssignVendorsSelectInput } from 'components/UI/FormItems/AssignVendorsSelectInput';

export const EditCustomerForm = (): JSX.Element => {
  return (
    <Form layout="vertical">
      <Row gutter={[32, 16]}>
        <Col span={12}>
          <TextInput fieldName="name" label="Name" />
        </Col>
        <Col span={12}>
          <TextInput fieldName="description" label="Description" />
        </Col>
        <Col span={12}>
          <TextInput disabled fieldName="erpId" label="Erp Id" />
        </Col>
        <Col span={12}>
          <TextInput disabled fieldName="dataAreaId" label="Data Area Id" />
        </Col>
        <Col span={12}>
          <AssignOwnersSelectInput fieldName="assignedOwners" label="Assigned Owners" />
        </Col>
        <Col span={12}>
          <AssignVendorsSelectInput fieldName="assignedVendors" label="Assigned Vendors" />
        </Col>
      </Row>
    </Form>
  );
};
