import { Avatar, Button, Col, List, message, Modal, Row, Space, Typography } from 'antd';
import MdsiIcon from 'assets/mdsi-icon.svg';
import { CaretSpinIcon } from 'components/atoms/CaretSpinIcon';
import { LegacyRef, MouseEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import { useRecoverBusinessMutation } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { setCustomerSearch } from 'redux/slices/businessListSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { InlineStylesModel } from '../../models/InlineStylesModel';
import { DivisionList } from './DivisionListItem';

const styles: InlineStylesModel = {
  wrapper: {
    backgroundColor: 'white',
    paddingLeft: 10,
    borderRadius: 5
  },
  container: {
    alignItems: 'center'
  },
  title: {
    fontWeight: '100',
    marginBottom: 0
  },
  altTitle: {
    fontWeight: '100',
    color: '#18a799',
    marginBottom: 0
  }
};

export const BusinessListItem = ({ business }: any): JSX.Element => {
  /* ******************** Hooks ******************** */
  const [showDivision, _setShowDivisions] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { pathname } = useLocation();
  const { customerSearch } = useAppSelector((state) => state.businessList);
  const [recoverBusiness] = useRecoverBusinessMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [ref, { height }] = useMeasure();

  const expandAnimation = useSpring({
    config: { friction: 12 },
    height: showDivision ? height : 0
  });

  const isRecycleBinPage = pathname.includes('recycling-bin');

  /* ******************** Functions ******************** */
  const handleToggleShowDivision = (ev: MouseEvent<HTMLElement>): void => {
    ev.preventDefault();
    ev.stopPropagation();
    _setShowDivisions((prevState) => !prevState);
  };

  const handleEditBusiness = (): void => {
    navigate(`/business/${business.id}`);
    dispatch(setCustomerSearch(''));
  };
  const modal = () =>
    Modal.confirm({
      title: `Recover Business ${business.name}`,
      onOk: handleOk,
      content: 'Do you wish to recover this business?'
    });
  const handleRecover = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    ev.preventDefault();
    modal();
  };

  const handleOk = async (): Promise<void> => {
    try {
      await recoverBusiness({ businessId: business.id as string }).unwrap();
      message.success('Business successfuly recovered');
      setIsModalOpen(false);
    } catch (error) {
      console.log(error);
      message.error((error as { data: string }).data ?? 'Business failed to be recovered!');
    }
  };

  const handleExit = (ev: MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    ev.preventDefault();
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (customerSearch) {
      _setShowDivisions(true);
    }
    if (!business.divisions.length) _setShowDivisions(false);
  }, [business.divisions.length, customerSearch]);

  useEffect(() => {
    if (customerSearch === '') {
      _setShowDivisions(false);
    }
  }, [customerSearch]);

  if ((business.divisions.length && isRecycleBinPage) || business.isDeleted) {
    return (
      <>
        <List.Item onClick={business.divisions.length ? handleToggleShowDivision : undefined} style={business.divisions.length ? { ...styles.wrapper, ...{ cursor: 'pointer' } } : styles.wrapper}>
          <List.Item.Meta
            avatar={<Avatar src={business.logoUrl ? business.logoUrl : MdsiIcon} />}
            title={
              <Space>
                <Typography.Title level={5} style={styles.title}>
                  {business.name}
                </Typography.Title>
                <Typography.Title level={5} type="secondary" style={styles.title}>
                  |
                </Typography.Title>
                <Typography.Title level={5} type="secondary" style={styles.altTitle}>
                  {business.erpId}
                </Typography.Title>
                <Typography.Title level={5} type="secondary" style={styles.title}>
                  |
                </Typography.Title>
                <Typography.Title level={5} type="secondary" style={styles.altTitle}>
                  {isRecycleBinPage ? `${business.divisions.length} Deleted Divisions` : `${business.divisions.length} Divisions`}
                </Typography.Title>
              </Space>
            }
            style={styles.container}
          />
          {business.isDeleted && isRecycleBinPage ? (
            <Button type="primary" style={{ backgroundColor: '#18a799', border: 'none' }} onClick={handleRecover}>
              Recover
            </Button>
          ) : (
            <Button disabled={isRecycleBinPage ? true : false} onClick={handleEditBusiness} type="primary" key="edit-business">
              Edit
            </Button>
          )}
          <Button disabled={!business.divisions.length} style={{ width: 115 }} type="text" onClick={handleToggleShowDivision} key="show-divisions" icon={<CaretSpinIcon isOpen={showDivision} />}>
            {showDivision ? 'Collapse' : 'Expand'}
          </Button>
        </List.Item>
        <animated.div style={{ ...{ minHeight: 10, overflow: 'hidden' }, ...expandAnimation }}>
          {showDivision && (
            <div ref={ref as LegacyRef<HTMLDivElement>}>
              <DivisionList divisions={business.divisions} />
            </div>
          )}
        </animated.div>
      </>
    );
  } else if (!isRecycleBinPage) {
    return (
      <>
        <List.Item onClick={business.divisions.length ? handleToggleShowDivision : undefined} style={business.divisions.length ? { ...styles.wrapper, ...{ cursor: 'pointer' } } : styles.wrapper}>
          <List.Item.Meta
            avatar={<Avatar src={business.logoUrl ? business.logoUrl : MdsiIcon} />}
            title={
              <Space>
                <Typography.Title level={5} style={styles.title}>
                  {business.name}
                </Typography.Title>
                <Typography.Title level={5} type="secondary" style={styles.title}>
                  |
                </Typography.Title>
                <Typography.Title level={5} type="secondary" style={styles.altTitle}>
                  {business.erpId}
                </Typography.Title>
                <Typography.Title level={5} type="secondary" style={styles.title}>
                  |
                </Typography.Title>
                <Typography.Title level={5} type="secondary" style={styles.altTitle}>
                  {isRecycleBinPage ? `${business.divisions.length} Deleted Divisions` : `${business.divisions.length} Divisions`}
                </Typography.Title>
              </Space>
            }
            style={styles.container}
          />
          {business.isDeleted && isRecycleBinPage ? (
            <Button type="primary" danger onClick={handleRecover}>
              Recover
            </Button>
          ) : (
            <Button disabled={isRecycleBinPage ? true : false} onClick={handleEditBusiness} type="primary" key="edit-business">
              Edit
            </Button>
          )}
          <Button disabled={!business.divisions.length} style={{ width: 115 }} type="text" onClick={handleToggleShowDivision} key="show-divisions" icon={<CaretSpinIcon isOpen={showDivision} />}>
            {showDivision ? 'Collapse' : 'Expand'}
          </Button>
          <Modal visible={isModalOpen} onOk={handleOk} closable onCancel={handleExit}>
            <Row gutter={[32, 16]} align="middle" justify="center">
              <Col span={24}>
                <Typography>Do you wish to recover this Business?</Typography>
              </Col>
              <Col span={24}></Col>
            </Row>
          </Modal>
        </List.Item>
        <animated.div style={{ ...{ minHeight: 10, overflow: 'hidden' }, ...expandAnimation }}>
          {showDivision && (
            <div ref={ref as LegacyRef<HTMLDivElement>}>
              <DivisionList divisions={business.divisions} />
            </div>
          )}
        </animated.div>
      </>
    );
  }
  return <></>;
};
