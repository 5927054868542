import { Button, Card, Form, Modal, Row, Space, Table, Tooltip, Typography, message } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { SwitchInput, TextInput } from 'components/UI/FormItems';
import { TextAreaInput } from 'components/UI/FormItems/TextAreaInput';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import update from 'immutability-helper';
import { CourrierAccountModel, CreateDivisionPayload, courrierAccountPayload } from 'models/Division';
import { FC, useCallback, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { EditCourierAcount } from './EditCourierAcount';

interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

type Props = {
  courrierAccount: string;
};

const cols: ColumnType<CourrierAccountModel>[] = [
  {
    title: 'Priority',
    dataIndex: 'priority',
    key: 'priority'
  },
  {
    title: 'Courier Erp Code',
    dataIndex: 'courierErpCode',
    key: 'courierErpId',
    render: (_, record) => (
      <Tooltip title={`Description: ${record.description}` ?? undefined}>
        <Typography.Text>{record.courierErpCode}</Typography.Text>
      </Tooltip>
    )
  },
  {
    title: 'Courier Account Number',
    dataIndex: 'accountNumber',
    key: 'accountNumber'
  },
  {
    title: 'Is Default',
    dataIndex: 'isDefault',
    key: 'isDefault',
    render: (_, record) => <Typography.Text>{record.isDefault ? 'Yes' : 'No'}</Typography.Text>
  },
  {
    title: 'Actions',
    render: (_, record, idx) => <EditCourierAcount idx={idx} record={record} />
  }
];
export const CourrierAccountNumberTable: FC<Props> = ({ courrierAccount }) => {
  const { setFieldValue, values } = useFormikContext<CreateDivisionPayload>();
  const [isOpen, setIsOpen] = useState(false);

  const type = 'DraggableBodyRow';

  const formik = useFormik<CourrierAccountModel>({
    enableReinitialize: true,
    validationSchema: courrierAccountPayload,
    initialValues: {
      courierErpCode: '',
      accountNumber: '',
      description: '',
      priority: values.courierAccounts.length + 1 ?? 1,
      isDefault: false
    },
    onSubmit: (vals) => {
      if (values.courierAccounts.some((acc) => acc.accountNumber.toLowerCase() === vals.accountNumber.toLowerCase())) {
        message.error('Account number already exists');
        return;
      }
      if (values.courierAccounts.some((acc) => acc.courierErpCode.toLowerCase() === vals.courierErpCode.toLowerCase())) {
        message.error('Courier Erp Code already exists');
        return;
      }
      if (values.courierAccounts.some((acc) => acc.isDefault === true) && vals.isDefault) {
        message.error('There can only be one default account');
        return;
      }
      setFieldValue('courierAccounts', [...values.courierAccounts, vals]);
      setIsOpen(false);
      formik.resetForm();
    }
  });

  const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }: DraggableBodyRowProps): JSX.Element => {
    const ref = useRef<HTMLTableRowElement>(null);

    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};

        if (dragIndex === index) {
          return {};
        }

        return {
          isOver: monitor.isOver(),
          dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
        };
      },
      drop: (item: { index: number }) => {
        moveRow(item.index, index);
      }
    });
    const [, drag] = useDrag({
      type,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    });

    drop(drag(ref));

    return <tr ref={ref} className={`${className}${isOver ? dropClassName : ''}`} style={{ cursor: 'move', ...style }} {...restProps} />;
  };

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (!values.courierAccounts) return;
      const dragRow = values.courierAccounts[dragIndex];

      setFieldValue(
        'courierAccounts',
        update(values.courierAccounts, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        }).map((d, i) => ({ ...d, priority: i + 1, key: i }))
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.courierAccounts]
  );

  const components = {
    body: {
      row: DraggableBodyRow
    }
  };

  return (
    <Card
      headStyle={{ background: 'rgb(255, 234, 193)' }}
      title={
        <Row justify="space-between">
          <AntPageTitle text={'Courier Accounts'} />
          <Button type="primary" onClick={() => setIsOpen(true)}>
            Add Account
          </Button>
        </Row>
      }>
      <DndProvider backend={HTML5Backend}>
        <Table
          pagination={false}
          components={components}
          onRow={(_, index): any => {
            const attr = {
              index,
              moveRow
            };

            return attr as React.HTMLAttributes<any>;
          }}
          dataSource={values.courierAccounts}
          columns={cols}
        />
      </DndProvider>
      <Modal
        width={350}
        onOk={() => formik.submitForm()}
        onCancel={() => {
          setIsOpen(false);
          formik.resetForm();
        }}
        closable
        open={isOpen}
        title="Add Courrier Account Number">
        <FormikProvider value={formik}>
          <Form layout="vertical" style={{ width: '100%' }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <TextInput fieldName="courierErpCode" label="Courier Erp Code" />
              <TextInput fieldName="accountNumber" label="Courrier Account Number" />
              <TextAreaInput fieldName="description" label="Description" />
              <SwitchInput fieldName="isDefault" label="Is Default" labelPosition="top" isInheritanceBusiness={false} />
              {/* <TextInput fieldName="courrierErpCode" /> */}
            </Space>
          </Form>
        </FormikProvider>
      </Modal>
    </Card>
  );
};
