import { Space, Switch, SwitchProps, Typography } from 'antd';
import { useField } from 'formik';
import { FC } from 'react';
interface Props extends SwitchProps {
  fieldName: string;
  label?: string;
  labelPosition?: 'top' | 'right';
  isInheritanceBusiness: boolean;
}

export const SwitchInput: FC<Props> = ({ fieldName, label, labelPosition, isInheritanceBusiness, ...rest }) => {
  const [{ value }, meta, { setValue }] = useField<boolean>(fieldName);

  return (
    <Space direction="vertical" size={1}>
      <Typography.Text>{label}</Typography.Text>
      <Switch checked={value} onChange={(checked) => setValue(checked)} disabled={isInheritanceBusiness || rest.disabled} />
    </Space>
  );
};
