import { Form, Select, SelectProps, Spin } from 'antd';
import { Loader } from 'components/atoms/Loader';
import { useField } from 'formik';
import { useGetVendorsQuery } from 'redux/services/simonSoundman/simonSoundmanApi';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps {
  fieldName: string;
  label?: string;
  businesses?: any;
}

export const AssignVendorsSelectInput = ({ fieldName, businesses, label, ...rest }: Props): JSX.Element => {
  const [{ value }, { touched, error }, { setTouched, setValue }] = useField<string[]>(fieldName);

  const { data, isLoading } = useGetVendorsQuery({ overrideSkipTake: true, dataAreaId: 'mdsi' });
  // const uniqueVendors = [...new Set(data?.data)];

  const options = data?.data.map((owner) => ({ label: `${owner.vendorAccountNumber}: ${owner.vendorName}`, value: owner.vendorAccountNumber, key: owner.vendorAccountNumber }));

  const handleChange = (val: string[]) => {
    setValue(val);
  };

  return (
    <Spin spinning={isLoading} indicator={<Loader medium />}>
      <Form.Item
        labelAlign="left"
        validateStatus={touched && error ? 'error' : 'success'}
        label={label ? <FormLabel label={label} /> : undefined}
        help={touched && error ? error : undefined}
        style={{ marginBottom: 0 }}
        labelCol={{ style: { padding: 0 } }}>
        <Select
          {...rest}
          mode="multiple"
          options={options}
          value={value || undefined}
          onChange={(val) => handleChange(val)}
          onFocus={() => setTouched(false)}
          onBlur={() => setTouched(true)}
          style={{ borderRadius: 5 }}
        />
      </Form.Item>
    </Spin>
  );
};
