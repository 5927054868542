import { Col, Form, Row } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { TextInput } from 'components/UI/FormItems';
import { AssignOwnersSelectInput } from 'components/UI/FormItems/AssignOwnersSelectInput';
import { SelectInput } from 'components/UI/FormItems/SelectInput';
import { useGetBusinessesQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';

export const CreateDivisionForm = (): JSX.Element => {
  const { data: businessList } = useGetBusinessesQuery({ includeDivisions: true });
  const businessNameOption: DefaultOptionType[] | undefined = businessList?.data?.map((business) => ({ value: business.name, label: business.name }));

  return (
    <Form layout="vertical">
      <Row gutter={[32, 16]}>
        <Col span={12}>
          <TextInput fieldName="name" label="Name" />
        </Col>
        <Col span={12}>
          <SelectInput options={businessNameOption} businesses={businessList} label="Business" fieldName="business" />
        </Col>
        <Col span={12}>
          <TextInput fieldName="businessId" label="Business Id" />
        </Col>
        <Col span={12}>
          <TextInput fieldName="description" label="Description" />
        </Col>
        <Col span={12}>
          <TextInput fieldName="erpId" label="Erp Id" />
        </Col>
        <Col span={12}>
          <AssignOwnersSelectInput mode="multiple" fieldName="assignedOwners" label="Assigned Owners" />
        </Col>
      </Row>
    </Form>
  );
};
