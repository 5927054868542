import { LocationAddressTypes } from 'redux/services/chuckieSue/models/addresses';
import * as yup from 'yup';
import { Application } from './Application';

export interface Division {
  id: string;
  acuityV3ErpId: string;
  assignedOwners: string[];
  assignedVendors: string[];
  applications: Application[];
  business: {
    applications: Application[];
    dataAreaId: string;
    erpId: string;
    id: string;
    name: string;
  };
  biLogin: {
    spaceId?: string;
    ssoPassword?: string;
    userId?: string;
  };
  conditions: [];
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  courierAccounts: CourrierAccountModel[];
  description: string;
  dispositions: [];
  documentType: string;
  erpId: string;
  isActive: boolean;
  isDeleted: boolean;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  name: string;
  owners: string[];
  partitionKey: string;
  partitionKeyDescription: string;
  deploymentRequestConfiguration?: {
    allowAddressToBeAddedToPostOffice: boolean;
    alternateItemIdLabel: string | null;
    autoAddManualAddressToPostOffice: boolean;
    checkQuantityAvailableOnPO: boolean;
    configurationSource: string;
    displayStandardCost: boolean;
    enableProjects: boolean;
    enableTasks: boolean;
    includeAssetOwner: boolean;
    includeCustomerOrderType: boolean;
    includeDrawingLocation: boolean;
    installLocationAddressTypes: LocationAddressTypes[];
    isAlternateItemIdEnabled: boolean;
    isBackorderAllowed: boolean;
    isImplementerEnabled: boolean;
    isManualAddressEntryEnabled: boolean;
    isPartialShippingAllowed: boolean;
    isTier1ApprovalWorkflowEnabled: boolean;
    isTier2ApprovalWorkflowEnabled: boolean;
    isUseMyContactInfoEnabled: boolean;
    shipmentLocationAddressTypes: LocationAddressTypes[];
    tier1ApprovalThreshold: 0;
    tier2ApprovalThreshold: 0;
  };
  extendedDeploymentRequestConfiguration?: {
    implementers: [];
    notificationEmails: string[];
  };
  projectConfiguration?: {
    areProjectUsersEnabled: boolean;
    configurationSource: string;
    isAutoDispositionCreationEnabled: boolean;
    validateDispositionOnProjectCreate: boolean;
  };
  extendedProjectConfiguration?: {
    projectTypes: string[];
    userDefinedFields: string[];
  };
  // This no longer lives on a division but lives on rudy cadabby but kept it for less breaking changings on business list.
  productCatalogConfiguration?: {
    dataInheritanceLevel: 'Division' | 'Business' | 'BusinessWithDivisionOverride';
  };
}

export interface CourrierAccountModel {
  courierErpCode: string; //required and unique
  accountNumber: string; //required and unique
  isDefault?: boolean; // only 1 can be set to true
  description?: string;
  priority?: number | null; // required and unique
}

export interface DivisionShort {
  description: string;
  documentType: string;
  erpId: string;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  name: string;
}

export interface GetDivisionsParams {
  includeDeletedData?: boolean;
  includeInactiveData?: boolean;
  returnDeletedDivisionsOnly?: boolean;
  orderByDirection?: string;
  orderByField?: string;
  divisionNameContains?: string;
  businessId?: string;
  dataAreaId?: string;
  businessNameContains?: string;
}

export interface EditDivisionPayload {
  name: string;
  description: string;
  erpId: string;
  dataAreaId: string;
  assignedOwners: string[];
  assignedVendors: string[];
  courierAccounts: CourrierAccountModel[];
  spaceId?: string;
  ssoPassword?: string;
  userId?: string;
  biLogin: {
    spaceId?: string;
    ssoPassword?: string;
    userId?: string;
  };
}

export interface CreateDivisionPayload {
  isActive: boolean;
  businessId: string;
  name: string;
  description: string;
  erpId: string;
  business: string;
  assignedOwners: string[];
  assignedVendors: string[];
  courierAccounts: CourrierAccountModel[];
}

export const divisionPayload: yup.SchemaOf<CreateDivisionPayload> = yup.object({
  name: yup.string().label('Name').required(),
  description: yup.string().label('Description').required(),
  erpId: yup.string().label('Erp Id').required(),
  business: yup.string().label('Business Name').required(),
  isActive: yup.boolean().label('Is Active').required(),
  businessId: yup.string().label('Business Id').required(),
  assignedOwners: yup.array().label('Assigned Owners'),
  assignedVendors: yup.array().label('Assigned Vendors'),
  courierAccounts: yup.array()
});
export const courrierAccountPayload: yup.SchemaOf<CourrierAccountModel> = yup.object({
  description: yup.string().label('Description').notRequired(),
  courierErpCode: yup.string().label('Courier Erp Code').required(),
  accountNumber: yup.string().label('Account Number').required(),
  priority: yup.number().label('Priority').nullable(),
  isDefault: yup.boolean().label('Is Default').notRequired()
});
