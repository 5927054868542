import { List } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { BusinessListItem } from 'components/molecules/BusinessListItem';
import { useGetBusinessesQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';

export const RecycleBinList = (): JSX.Element => {
  const { data: businessData, isLoading, isFetching } = useGetBusinessesQuery({ includeDivisions: true, includeDeletedData: true, returnDeletedDivisionsOnly: true });

  /* ******************** Render ******************** */
  return (
    <List
      loading={{
        indicator: <BlockLoader direction="loader loader--slideUp" />,
        size: 'large',
        spinning: isLoading || isFetching
      }}
      dataSource={businessData?.data}
      renderItem={(item): JSX.Element => <BusinessListItem business={item} />}
      rowKey={(item: any): string => item.id}
    />
  );
};
